function slidePositionPlain(globalUnits, slideIndexProperties) {
  //console.log("POSITIONING OF SLIDE ", globalUnits - slideIndexProperties.start)
  let x = 1 - (globalUnits - slideIndexProperties.start)

  let style = {
    left: x * 100 + "%"
  }
  return style
}

function slidePositionParallax(globalUnits, props) {
  // parallax: true
  const easeInLength = props.easeInLength || 1
  const easeOutLength = props.easeOutLength || 1
  const displayLength =
    Number(props.end - props.start) - (easeInLength + easeOutLength)
  const contentLength = props.contentLength

  const contentStart = easeInLength
  const contentEnd = easeInLength + displayLength

  const relUnits = globalUnits - props.start

  let left

  if (relUnits <= contentStart) {
    left = lerp(1, 0, relUnits / easeInLength)
  } else if (relUnits > contentStart && relUnits < contentEnd) {
    left = lerp(
      0,
      -(contentLength - 1),
      (relUnits - contentStart) / displayLength
    )
  } else {
    left = lerp(
      -(contentLength - 1),
      -contentLength,
      (relUnits - contentEnd) / easeOutLength
    )
  }

  return {
    left: left * 100 + "%"
  }
}

function lerp(start, end, e) {
  return start + (end - start) * e
}

export { slidePositionPlain, slidePositionParallax }
