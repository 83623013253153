<template>
  <div :style="slidePosition" class=" z-4 w-100 h-100">
    <div
      class="position-absolute bg-dark-green-0-75 border-warning text-warning square23"
      style="top:50%; left:2.5%;width:60%;padding:2.5% 2.5%;transform: translateY(-50%);"
    >
      <h6 class="text-white font-vw-3-15 font-w-600 fade23 " style="opacity:0;">
        2017
      </h6>
      <h6 class="text-white font-vw-3-15 font-w-600 fade23 " style="opacity:0;">
        A NATIONAL INNOVATION ENGINE
      </h6>
      <p class=" text-white font-vw-1-5 text23 mb-0" style="opacity:0;">
        From the first days of TRIUMF’s construction and the transfer of
        accelerator technologies, to more recent medical isotope advances and
        industrial partnerships, TRIUMF’s search for new knowledge and wealth of
        technological expertise have driven Canadian commercial growth since the
        mid-1990s. Today, TRIUMF Innovations contains a team of business and
        technology transfer professionals dedicated to further accelerating the
        lab’s business and commercialization relationships. Launched in 2017,
        TRIUMF Innovations builds on a decades-long history of working alongside
        industry partners in order to deliver benefits to people in Canada and
        around the world.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },

  mounted() {
    //this.startLoop()
  },
  methods: {
    slide23(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 1000
      })

      animation
        .add({
          targets: ".square23",
          opacity: [0, 1],
          translateY: ["50%", "-50%"],
          duration: 600,
          delay: 5000,
          easing: "easeInQuart"
        })
        .add({
          targets: ".fade23",

          translateY: ["1vh", "0vh"],
          opacity: [0, 1],
          duration: 1000,
          easing: "easeInOutCubic",
          delay: (el, i) => 22000 + 1200 * i
        })
        .add({
          targets: ".text23",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 1200,
          delay: 2000,
          easing: "easeInOutCubic"
        })

      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * (1 - percentage)
      )
    }
  },
  watch: {
    innerPosition() {
      this.slide23(this.innerPosition)
    }
  }
}
</script>

<style></style>
